<template>
  <div class="content" v-loading="loadingStatus.getTable">
    <div>
      <el-form ref="staffRef" size="medium" :model="dataForm" label-width="90px">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="所属工会：">
              <el-select style="width: 100%;" v-model="dataForm.presidentId" placeholder="请选择" clearable filterable @change="presidentIdChange">
                <el-option v-for="(item ) in presidentList" :key="item.id"
                           :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col  :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="小组：">
              <el-select style="width: 100%" v-model="dataForm.groupId" placeholder="请选择" clearable @change="groupIdChange">
                <el-option v-for="(item ) in groupList" :key="item.id"
                           v-show="item.presidentId === dataForm.presidentId || !dataForm.presidentId"
                           :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col  :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="员工：">
              <el-select style="width: 100%" v-model="dataForm.stuffId" placeholder="请选择" clearable>
                <el-option v-for="(item) in empList" :key="item.id"
                           v-show="item.groupId=== dataForm.groupId || !dataForm.groupId"
                           :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
<!--          <el-col  :xs="24" :sm="12" :md="12" :lg="8" :xl="6">-->
<!--            <el-form-item label="游戏：">-->
<!--              <el-select style="width: 100%" v-model="dataForm.gameId" placeholder="请选择">-->
<!--                <el-option v-for="(item ,index) in gamesList" :key="item.id"-->
<!--                           :label="item.name" :value="item.id"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col  :xs="24" :sm="12" :md="12" :lg="8" :xl="6">-->
<!--            <el-form-item label="服务器：">-->
<!--              <el-input placeholder="请输入服务器"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col  :xs="24" :sm="12" :md="12" :lg="8" :xl="6">-->
<!--            <el-form-item label="日期：">-->
<!--              <el-date-picker-->
<!--                  style="width: 100%"-->
<!--                  v-model="dataForm.selectDate"-->
<!--                  value-format="yyyy-MM-dd"-->
<!--                  type="date"-->
<!--                  placeholder="选择日期">-->
<!--              </el-date-picker>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <div style="display: inline-block;padding-left: 20px;padding-bottom: 20px">
            <el-button type="primary" @click="getTable" size="medium">查询</el-button>
            <el-button plain @click="getReset" size="medium">重置</el-button>
          </div>
        </el-row>

      </el-form>
    </div>
    <div>
      <el-table
          :data="tableData"
          border
          :stripe="true"
          style="width: 100%">
        <el-table-column prop="presidentName" width="140" label="所属公会" align="center"></el-table-column>
        <el-table-column width="180" prop="dateResp" label="当前时间" align="center"></el-table-column>
        <el-table-column width="140" prop="stuffId" label="员工ID" align="center"></el-table-column>
        <el-table-column width="140" prop="stuffName" label="员工名称" align="center"></el-table-column>
        <el-table-column prop="thatDayRegister" label="当日注册" align="center"></el-table-column>
        <el-table-column prop="thatDayRecharge" label="当日充值" align="center"></el-table-column>
        <el-table-column prop="threeRecharge" label="3日充值" align="center"></el-table-column>
        <el-table-column prop="fiveRecharge" label="5日充值" align="center"></el-table-column>
        <el-table-column prop="sevenRecharge" label="7日充值" align="center"></el-table-column>
      </el-table>
      <el-pagination
          class="page"
          background
          @size-change="handleSizeChange"
          @current-change="currentChange"
          :page-sizes="[10, 15, 20]"
          :page-size="1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
          :current-page="page.pageNo"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import loadingStatusMixin from "@/mixins/loadingStatus";
import {commonInfo, dataStatisticsEmpPerformanceList} from "../../service/moudules/promotionLinkApi";

export default {
  name: "employeePerformance",
  mixins: [loadingStatusMixin],

  data (){
    return {
      dataForm: {
        groupId: '', //组ID
        stuffId: '', //员工ID
        gameId: '', //游戏ID
        selectDate: '', //查询时间
        serviceId: '', //服务器ID
        presidentId: '', //工会ID
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      groupList: [], //小组
      empList: [], //员工信息
      gamesList: [], //游戏
      presidentList: []
    }
  },
  mounted() {
    this.getTable();
    this.getCommonInfo();
  },
  methods:{
    presidentIdChange(value) {
        this.dataForm.groupId = ''
        this.dataForm.stuffId = ''
    },
    groupIdChange(value) {
        this.dataForm.stuffId = ''
    },
    async getCommonInfo() {
      let res = await commonInfo();
      if (res.code == 200) {
        this.groupList = res.data.groupList;
        this.empList = res.data.empList;
        this.gamesList = res.data.gamesList;
        this.presidentList = res.data.presidentList;

      }
    },
    async getTable() {
      if ( this.hasLoadingState('getTable') ) return;
      try {
        this.addLoadingState('getTable');
        let params = {
          pageNo: this.page.pageNo,
          pageSize: this.page.pageSize
        };
        const searchFormValue = this.dataForm;
        Object.keys(searchFormValue).map((key) => {
          searchFormValue[key] && (params[key] = searchFormValue[key]);
        });
        let res = await dataStatisticsEmpPerformanceList(params);
        this.removeLoadingState("getTable");
        if ( res.code == 200) {
          this.tableData = res.data.records;
          // this.page.pageNo = res.data.pages;
          this.page.pageNo = res.data.current;
          this.page.total = res.data.total;
        } else {
          this.removeLoadingState("getTable");
          this.$message.error(`${res.msg}！`);
        }
      } catch (e) {
        this.removeLoadingState("getTable");
      }
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNo = 1;
      this.getTable();
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getTable();
    },
    getReset() {
      this.dataForm = {
        groupId: '', //组ID
        stuffId: '', //员工ID
        gameId: '', //游戏ID
        selectDate: '', //查询时间
        serviceId: '', //服务器ID
        presidentId: ''
      };
      this.getTable();
    },
  },

}
</script>

<style scoped lang="less">
.content{
  padding: 20px;
  margin: 10px;
  border: 1px solid #dcdfe6;
  text-align: initial;
  background-color: white;
  .con-tab{
    background-color: #e6a23c2b;
    padding: 10px 8px;
    border-radius: 8px;
    font-size: 14px;
    color: #e6a23c;
    margin-bottom: 20px;
  }
  .page {
    text-align: right;
    margin-top: 15px;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar{
    width: 1px;
    height: 10px;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: rgba(0,0,0,.2);
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-track{
    border-radius: 10px;
    background-color: rgba(0,0,0,.1);
  }
  ::v-deep .el-table__header th.el-table__cell{
    height:50px;
    background-color:#fafafa;
    color:#333333;
    padding:0
  }
}

</style>